import axios from "axios"
import dateUtility from "@/services/dateUtility"
import { isEmpty } from "@/services/objectUtility"

async function getBucketsWithItems(
    bucketApiMethod,
    itemApiMethod,
    sortBy,
    bucketForeignKey,
    modifyBuckets,
    modifyItems,
    filter) {
    let buckets = await getBuckets(bucketApiMethod, sortBy, filter);
    if (modifyBuckets) {
        await modifyBuckets(buckets);
    }
    let items = await getItems(itemApiMethod);
    if (modifyItems) {
        await modifyItems(items);
    }
    appendItemsToBuckets(buckets, items, bucketForeignKey);
    return buckets;
}
async function getBuckets(bucketApiMethod, sortBy, filter) {
    let params = { sortBy };
    if (!isEmpty(filter)) {
        params.filter = filter;
    }
    let response = await axios.post("/api/" + bucketApiMethod + "/List", params);
    let buckets = response.data.items;
    return buckets;
}
async function getItems(itemApiMethod) {
    let itemResponse = await axios.post("/api/" + itemApiMethod + "/List");
    let items = itemResponse.data.items;
    return items;
}
function appendItemsToBuckets(buckets, items, bucketForeignKey) {
    for (let i = 0; i < buckets.length; i++) {
        let bucket = buckets[i];
        let itemsForBucket = items
            .filter(e => e[bucketForeignKey] === bucket.id);
        if (itemsForBucket.length > 1) {
            itemsForBucket = itemsForBucket
                .sort((a, b) => a.name.localeCompare(b.name));
        }
        bucket.items = itemsForBucket;
    }
}

function getSampleSetsWithTests(filter) {
    return getBucketsWithItems(
        "SampleSets",
        "SampleSetTests",
        "samplesToBeMailedOn",
        "sampleSetId",
        setOrganisations,
        setTestsFromLab,
        filter);
}

function getLabsWithTests() {
    return getBucketsWithItems("Labs", "LabTests", "name", "labId", null, setTests);
}

function getTestGroupsWithTests() {
    return getBucketsWithItems("TestGroups", "Tests", "name", "testGroupId", null, null);
}

async function setOrganisations(sampleSets) {
    let organisationIds = sampleSets.map(e => e.labClientOrganisationId);
    let organisationReponse = await axios.post(
        "/api/LabClientOrganisations/ListFromIds",
        organisationIds)
    let organisations = organisationReponse.data.items;
    sampleSets.forEach(e => e.name = dateUtility.formatIsoAsDate(e.samplesToBeMailedOn) + " "
        + organisations.find(o => o.id === e.labClientOrganisationId).name);
}

async function setTestsFromLab(items) {
    let labTestIds = items.map(e => e.labTestId);
    let labTestResponse = await axios.post("/api/LabTests/ListFromIds", labTestIds);
    let labTests = labTestResponse.data.items;
    let testIds = labTests.map(e => e.testId);
    let testResponse = await axios.post("/api/Tests/ListFromIds", testIds);
    let tests = testResponse.data.items;
    items.forEach(e => e.name = tests.find(t => t.id === getTestId(e.labTestId, labTests)).name);
}

function getTestId(labTestId, labTests) {
    return labTests.find(e => e.id === labTestId).testId;
}

async function setTests(items) {
    let testIds = items.map(e => e.testId);
    let testResponse = await axios.post("/api/Tests/ListFromIds", testIds);
    let tests = testResponse.data.items;
    items.forEach(e => e.name = tests.find(t => t.id === e.testId).name);
}

export default {
    getBucketsWithItems,
    getSampleSetsWithTests,
    getLabsWithTests,
    getTestGroupsWithTests
};
